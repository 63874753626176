import { 
    SEARCH_INSURANCES_RECOMMENDATIONS, 
    SEND_PARAMS_SEARCH,
    SEARCH_INSURANCE_BY_ID, 
    SELECT_INSURANCE,
    SEARCH_INSURANCES_RECOMMENDATIONS_ERROR
} from "../actions/types";

const initialState = {
    insurances : [],
    searchParams: null,
    isLoadingRecommendations : true,
    insuranceSelected : null,
    error: null
}


//agregar luego el nombre de la funcion
export default  function insurancesReducerFuntion (state = initialState, action) {
    switch (action.type) {
        case SEARCH_INSURANCES_RECOMMENDATIONS:               
            let recommendations = action.payload;   
            const engine = action.engine;           
        return Object.assign({},
            {
                ...state,
                recommendations : recommendations,
                insurances : recommendations,
                isLoadingRecommendations : false,
                engine: engine,
                error: recommendations.length > 0 ? null : 204
            });
        case SEARCH_INSURANCES_RECOMMENDATIONS_ERROR:
            const error = action.payload;
            return Object.assign({},
                {
                    ...state,
                    error: error,
                    isLoadingRecommendations : false
                }
            )  
        
        case SEND_PARAMS_SEARCH:
            return Object.assign({},
                {
                    ...state,
                    searchParams : action.payload
                })
    
        case SELECT_INSURANCE:
            return Object.assign({}, state, action.payload)
        default:
            return state;
    }
}