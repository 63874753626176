import { 
  FILL_PASSENGER_FORM,
  FILL_CONTACT_FORM,
  FILL_PAYMENT_CREDIT_CARD_FORM,
  FILL_COMMISSION_AGENCY
 } from "./types";


export const fillPassengerForm = (data) => async dispatch => {
  dispatch({
    type: FILL_PASSENGER_FORM,
    passenger: data.passenger
  });
}

export const fillContactForm = (data) => async dispatch => {
  dispatch({
    type: FILL_CONTACT_FORM,
    payload: data
  });
}

export const fillPaymentCreditCardForm = (data) => async dispatch => {
  dispatch({
    type: FILL_PAYMENT_CREDIT_CARD_FORM,
    payload: data
  });
}

export const fillCommissionAgency = (data) => async dispatch => {
  dispatch({
    type: FILL_COMMISSION_AGENCY,
    payload: data
  })
}