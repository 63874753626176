import React, { Component } from "react";
import generalErrorIcon from "../../assets/images/icons/general_error.png";
import noResultsErrorIcon from "../../assets/images/icons/no_results_error.png";
import "../../assets/stylesheets/Error.css";

class ErrorSearch extends Component {

    getTitleErrorAndText() {
        let title = "tile";
        let text = "text test";
        let icon = generalErrorIcon;
        const error = this.props.error;

        switch (error) {
            case 500:
                title = "Disculpe las molestias...";
                text = "Al parecer tenemos problemas internos al momento de realizar la búsqueda. Por favor vuelva a intentarlo en unos minutos.";
                break;
            case 204:
                title = "¡Lo sentimos! no hay resultados";
                text =
                "Al parecer los parámetros de búsqueda no coinciden con ningún resultado. Por favor intenta con otras fechas.";
                icon = noResultsErrorIcon;
                break;
            case 401:
                title = "Token inválido";
                text = 
                  "Su búsqueda tiene un token de acceso que ya expiró o no pertenece a su agencia, por favor, generar un nuevo url de búsqueda desde su plataforma de ventas";          
                break;
            case 402:
                title = "Token inexistente";
                text =
                    "Su búsqueda no tiene un token de acceso, por favor, generar un enlace de búsqueda desde su plataforma de ventas";
                break;
            default:
                title = "Disculpe las molestias...";
                text = "Al parecer tenemos problemas internos al momento de realizar la búsqueda. Por favor vuelva a intentarlo en unos minutos.";
                break;
        }

        return {
            title: title,
            text: text,
            icon: icon
        }
    }

    render() {
        const { title, text, icon } = this.getTitleErrorAndText();
        return (
            <div className="ErrorMessage">
                <div className="ErrorMessage__image">
                    <img src={icon} alt="Error icon" />
                </div>
                <div className="ErrorMessage__content">
                    <p className="ErrorMessage__title">{title}</p>
                    <p className="ErrorMessage__text">{text}</p>
                </div>
                <div className="ErrorMessage__action">
                    <button className="button white">Ir a la página principal</button>
                </div>
            </div>
        )
    }

}

export default ErrorSearch;