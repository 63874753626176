import axios from "axios";
import responseInsurances from '../data/insurancesRecommendations.json';
import { 
    SEARCH_INSURANCES_RECOMMENDATIONS,
    SEND_PARAMS_SEARCH,
    SELECT_INSURANCE,
    SEARCH_INSURANCES_RECOMMENDATIONS_ERROR,
} from "./types";
import {
    BASE_URL
  } from "../config/keys";
import { TERMINAL_ID_PARAM_INDEX, TOKEN_PARAM_INDEX } from "../constants/searchParamConstants";

const requestConfig = {
    withCredentials: true
};

const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
  };

export const sendParamsToSearch = (data) => dispatch => {
    dispatch({
        type : SEND_PARAMS_SEARCH,
        payload : data
    })
}

export const searchInsurancesRecommendations = (data) => async dispatch => {
    const ENGINE_LOAD_URI = `${BASE_URL}/api/engines/${data.terminalId}`;
    const responseLoadEngine = await axios.get(ENGINE_LOAD_URI, requestConfig);
    if (responseLoadEngine.data && responseLoadEngine.data.profile) {
        const isAgency = responseLoadEngine.data.profile.typeId === "1" ? true : false;
        if(!isAgency) {
            const INSURANCE_RECOMMENDATIONS_URI = `${BASE_URL}/api/insurances/search`;
            const responseInsurancesRecommendations = await axios.post(INSURANCE_RECOMMENDATIONS_URI, data, config);
            dispatch({
                type: SEARCH_INSURANCES_RECOMMENDATIONS,
                payload: responseInsurancesRecommendations.data.recommendations,
                engine: responseLoadEngine.data
            });
        } else {
            const token = data.pathArray[TOKEN_PARAM_INDEX];
            if(!token) {
                dispatch({
                    type: SEARCH_INSURANCES_RECOMMENDATIONS_ERROR,
                    payload: 402
                })
            } else {
                const INSURANCES_AUTH_SERVICE = `${BASE_URL}/api/auth/verify`;
                const terminalId = data.pathArray[TERMINAL_ID_PARAM_INDEX];
                const insurancesRQ = {
                    token: token,
                    accountId: terminalId
                }
                const responseInsurancesAuth = await axios.post(INSURANCES_AUTH_SERVICE, insurancesRQ, config);
                if (responseInsurancesAuth.data.status === true) {
                    const INSURANCE_RECOMMENDATIONS_URI = `${BASE_URL}/api/insurances/search`;
                    const responseInsurancesRecommendations = await axios.post(INSURANCE_RECOMMENDATIONS_URI, data, config);
                    dispatch({
                        type: SEARCH_INSURANCES_RECOMMENDATIONS,
                        payload: responseInsurancesRecommendations.data.recommendations,
                        engine: responseLoadEngine.data
                    });
                } else {
                    dispatch({
                        type: SEARCH_INSURANCES_RECOMMENDATIONS_ERROR,
                        payload: 401
                    })
                }

            }
                
        }            
    } else {
        dispatch({
            type: SEARCH_INSURANCES_RECOMMENDATIONS_ERROR,
            payload: 500
        })
    }
}

export const selectInsurance = (data) => async dispatch => {
    const res = await axios.post(`${BASE_URL}/api/sessions/save?id=${data.sessionID}`, data, requestConfig);
    if (res.status === 200) {
        dispatch({
            type: SELECT_INSURANCE,
            payload: data
        });
        window.location.href = `${window.location.origin}/checkout/${data.sessionID}`;
    }
}