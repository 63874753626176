import axios from "axios";
import { GET_TRANSACTION_BY_ID, 
        GET_TOKEN_BY_NIUBIZ,
        BOOKING_COMPLETE,
        BOOKING_ERROR } from "./types";

import {
    BASE_URL
} from "../config/keys";

const requestConfig = {
    withCredentials: true
}

export const getTransactionById = id => async dispatch => {
    const URI = `${BASE_URL}/api/transaction/` + id;
    const res = await axios.get(URI);
    if (res.data) {
        dispatch({
            type: GET_TRANSACTION_BY_ID,
            payload: res.data
        });
    } else {
        dispatch({
            type: GET_TRANSACTION_BY_ID,
            payload: null
        });
    }
}

export const getTokenByNiubiz = params => async dispatch => {
    const URI = `${BASE_URL}/api/transaction/niubiz/token`;
    const res = await axios.post(URI, params);

    dispatch({
        type: GET_TOKEN_BY_NIUBIZ,
        payload: res.data
    })

}

export const book = params => async dispatch => {
    //consulta servicio /book 
    const resBookTest = { status: 200, transactionId : "61eb5cc7fcfd59727c7d6d0d" }
    //llamar api de sesiones
    //redireccionar con history, pasando el params, transactionId
    if(resBookTest.status === 200) {
        const URL_REDIRECT = `${window.location.origin}/booking-result/${resBookTest.transactionId}`;
        window.location.href = URL_REDIRECT;
    } else {
        //debe crearse una página para mostrar todos los errores de reserva
        console.log('HUBO UN ERROR INTENTANDO RESERVAR')
    }
}

export const bookInsurance = (data, history) => async dispatch => {
    const URI = `${BASE_URL}/api/insurances/book`;
    const res = await axios.post(URI, data, requestConfig);
    console.log('bookingActions bookInsurance ', data);
    if (res.data.status === "1") {
        const dataSession = {
            registerRS: res.data, 
            bookForm: data.bookForm, 
            paymentWasProcessed: false 
        }
        const sessionResponse = await axios.post(`${BASE_URL}/api/sessions/save?id=${data.sessionID}`, dataSession, requestConfig);
        if (sessionResponse.status === 200) {
            const pathString = `/booking-result/${sessionResponse.data.sessionID}`;
            window.location.href = window.location.origin + pathString;
            dispatch({
                type: BOOKING_COMPLETE,
                payload: res.data
            });
        }
    } else {
        dispatch({
            type: BOOKING_ERROR,
            payload: res.data
        });
    }
}