import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { sendParamsToSearch, searchInsurancesRecommendations, } from '../../actions/insurancesActions';
import { loadEngine } from '../../actions/engineActions';
import Insurance from "./Insurance";
import Navbar from "../commons/Navbar";
import Footer from "../commons/Footer";
import enginesUtils from "../../utils/enginesUtils";
import { FaPlaneDeparture, FaPlaneArrival, FaRegCalendarAlt, 
        FaUserAlt, FaCheckCircle } from 'react-icons/fa';



import { useParams, useSearchParams } from 'react-router-dom';
import InsurancesEngine from "../engines/InsurancesEngine";
import Loading from "../commons/Loading";
import LoadingProgress from "../commons/LoadingProgress";
import BookingProgress from "../commons/BookingProgress";
import InsurancesEngineRecotize from "../engines/InsurancesEngineRecotize";
import InsurancesEnginesRecotize2 from "../engines/InsurancesEnginesRecotize2";
import { AGENT_PARAM_INDEX, AGES_PARAM_INDEX, DESTINATION_INDEX_PARAM_INDEX, END_DATE_PARAM_INDEX, ORIGIN_PARAM_INDEX, START_DATE_PARAM_INDEX, TERMINAL_ID_PARAM_INDEX, TOKEN_PARAM_INDEX } from "../../constants/searchParamConstants";
import Loading2 from "../commons/Loading2";
import Banner from "../commons/Banner";
import ErrorSearch from "../commons/ErrorSearch";


class InsurancesRecommendations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            origin: null,
            destination: null,
            pathArray: null
        }
    }

    componentDidMount = () => {
        let pathArray = enginesUtils.getPathArrayFromUrl(window.location.pathname)
        const destination = enginesUtils.getDestinationValueByDestinationIndex(pathArray[DESTINATION_INDEX_PARAM_INDEX]);
        let paramsExtra = window.location.search.split("&");
        const terminalId = paramsExtra[0].substring(12);
        const agentEmail = paramsExtra.length > 1 ? paramsExtra[1].substring(6) : null;
        const token = paramsExtra.length > 2 ? paramsExtra[2].substring(6) : null;
        pathArray[TERMINAL_ID_PARAM_INDEX] = terminalId;
        pathArray[AGENT_PARAM_INDEX] = agentEmail;
        pathArray[TOKEN_PARAM_INDEX] = token;
        this.setState({ pathArray: pathArray }, () => {
            this.props.searchInsurancesRecommendations({
                destination: destination,
                terminalId: terminalId,
                pathArray: pathArray
            })
        })
    }

    onChange = e => {
        let value = e.target.value;
        this.setState({
            [e.target.name]: value
        })
    }

    render() {
        let isValidAge = true;
        if(this.state.pathArray) {
            isValidAge =  enginesUtils.isValidAgeForCotization(this.state.pathArray[AGES_PARAM_INDEX]); 
        }
        
        return (
            <div style={{backgroundColor: "lightgray"}}>
                <div className="container-fluid mt- mb-0" style={{backgroundColor: "#0050ab", color: "white"}}>
                    {this.state.pathArray !== null ?
                        <SearchParamsInformation
                            pathArray={this.state.pathArray}
                        /> : null
                    }
                    {this.state.pathArray !== null ? 
                        <InsurancesEnginesRecotize2 
                            statePrev={this.state.pathArray}
                        /> : null
                    }
                </div>
                <LoadingProgress activeStep={0} />
                <div className='container'>
                    <BookingProgress activeStep={0} />
                </div>

               
                {this.props.isLoading ? 
                    <div>
                        <Loading2 title={"Estamos buscando las mejores ofertas de seguros..."} style={{marginTop: "auto", marginBottom: "auto"}} />
                    </div> :
                    <div>
                        {
                        this.props.error ? <ErrorSearch error={this.props.error}  /> : 
                            <div className="container mb-3 mt-0">
                                {!isValidAge ? (
                                    <div className="alert alert-warning" role="alert">
                                        Viajeros mayores de 76 años requieren cotización individual
                                    </div>
                                ) : null}
                            
                            {
                                    this.props.recommendations.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <Insurance
                                                    index={index}
                                                    insurance={item}
                                                    params={this.state.pathArray}
                                                    engine={this.props.engine}
                                                    isAgency={this.props.engine.profile.typeId === "0" ? false : true}
                                                    recommendations={this.props.recommendations}
                                                />
                                            </div>
                                        )
                                    })
                            }</div>
                        }
                    </div>
                    }
                <Footer />
            </div>

        )
    }
}

const SearchParamsInformation = ({ pathArray }) => {
    const origin = pathArray[ORIGIN_PARAM_INDEX];
    const destination = enginesUtils.getDestinationValueByDestinationIndex(pathArray[DESTINATION_INDEX_PARAM_INDEX]);
    const startDate = pathArray[START_DATE_PARAM_INDEX];
    const endDate = pathArray[END_DATE_PARAM_INDEX];
    const ages = pathArray[AGES_PARAM_INDEX];
    const agesArray = ages.split(",");

    const coverageDays = enginesUtils.calculateElapsedDays(startDate, endDate);

    return (
        <div className="row p-2 SearchParamsInformation container ml-auto mr-auto">
            <div className="col-md-4 col-12">
                <div className="d-flex">
                    <div> <FaPlaneDeparture /> Origen: {origin}</div>
                    <div className="ml-2"> <FaPlaneArrival /> Destino: {destination}</div>
                </div>
            </div>
            <div className="col-md-4 col-12">
                <div className="d-flex">
                    <div><FaRegCalendarAlt /> Salida: {startDate}</div>
                    <div className="ml-2"><FaRegCalendarAlt /> Regreso: {endDate}</div>
                </div>
            </div>
            <div className="col-md-4 col-12">
                <div className="d-flex">
                    <div><FaUserAlt /> Cantidad de pasajeros: {agesArray.length}</div>
                    <div className="ml-2"><FaCheckCircle /> Días cobertura: {coverageDays}</div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ insurancesReducer }) => {
    return {
        recommendations: insurancesReducer.recommendations ? insurancesReducer.recommendations : [],
        searchParams: insurancesReducer.searchParams ? insurancesReducer.searchParams : null,
        isLoading : insurancesReducer.isLoadingRecommendations,
        engine: insurancesReducer.engine,
        error: insurancesReducer.error
    }
}

export default connect(mapStateToProps, { sendParamsToSearch, searchInsurancesRecommendations, loadEngine })(InsurancesRecommendations);